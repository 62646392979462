export const SAVE_BUTTON_ERROR_MESSAGE =
  'Your viewpoint needs a name before it can be saved';
export const UPDATE_BUTTON_ERROR_MESSAGE =
  'Your viewpoint needs a name before it can be updated';
export const VIEWPOINT_NAME_INPUT_ERROR_MESSAGE =
  'Please enter a Viewpoint name';
export const NO_PERMISSIONS_TO_MODIFY_VIEWPOINT =
  'You do not have permission to modify this viewpoint';
export const NO_PERMISSIONS_TO_CREATE_VIEWPOINT =
  'You do not have permission to create a viewpoint';
