import {
  appliedSettingsData$,
  getDefaultAppliedSettingsData,
} from './appliedSettingsData$';
import {
  saveAsViewpointInputs$,
  getDefaultInputsState,
} from './saveAsViewpointInputs$';
import { persistentReducedStream, streamReducer } from '@ardoq/rxbeach';
import { SaveAsViewpointState } from './types';
import { AppliedSettingsData } from '../appliedSettings/types';
import currentViewpointUserPermissions$ from '../../currentViewpointUserPermissions$';
import {
  NO_PERMISSIONS_TO_MODIFY_VIEWPOINT,
  NO_PERMISSIONS_TO_CREATE_VIEWPOINT,
} from './consts';
import { combineLatest, map } from 'rxjs';
import { ExtractStreamShape } from 'tabview/types';

type saveAsViewpointReducer<T> = (
  state: SaveAsViewpointState,
  payload: T
) => SaveAsViewpointState;

const saveAsViewpointInputsAndPermissions$ = combineLatest([
  saveAsViewpointInputs$,
  currentViewpointUserPermissions$,
]).pipe(map(([inputs, permissions]) => ({ ...inputs, ...permissions })));

const errorsReducer: saveAsViewpointReducer<
  ExtractStreamShape<typeof saveAsViewpointInputsAndPermissions$>
> = (previousState, inputsAndPermissionsData) => {
  const { canCreateViewpoint, canUpdateViewpoint } = inputsAndPermissionsData;
  const errors = {
    ...inputsAndPermissionsData.errors,
    saveButton: canCreateViewpoint
      ? inputsAndPermissionsData.errors.saveButton
      : NO_PERMISSIONS_TO_CREATE_VIEWPOINT,
    updateButton: canUpdateViewpoint
      ? inputsAndPermissionsData.errors.updateButton
      : NO_PERMISSIONS_TO_MODIFY_VIEWPOINT,
  };

  return {
    ...previousState,
    ...inputsAndPermissionsData,
    errors,
  };
};

const appliedSettingsDataReducer: saveAsViewpointReducer<
  AppliedSettingsData
> = (previousState, payload) => ({
  ...previousState,
  appliedSettings: payload,
});

export const saveAsViewpoint$ = persistentReducedStream(
  'saveAsViewpoint$',
  {
    ...getDefaultInputsState(),
    appliedSettings: getDefaultAppliedSettingsData(),
  },
  [
    streamReducer(saveAsViewpointInputsAndPermissions$, errorsReducer),
    streamReducer(appliedSettingsData$, appliedSettingsDataReducer),
  ]
);
