import { IconName } from '@ardoq/icons';
import { NavigationMenuGroup } from '../NavigationSidebar/types';
import { AccessControlPageStream } from '../types';
import { AccessControlTabs } from 'admin/accessControl/navigation/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { SSOAttributeMapping } from 'admin/ssoAttributeMapping';
import ManageSCIMToken from 'admin/orgMembers/SCIMManagement/ManageSCIMToken';
import { STRINGS as SSO_MAPPING_STRINGS } from 'admin/ssoAttributeMapping/consts';

export const getSSOIntegrationsMenuOption = (
  args: {
    showManageUserRoles: boolean;
    showIPAllowlisting: boolean;
    showSCIM: boolean;
    showSSOAttributeMapping: boolean;
    subdivisionFeature: boolean;
  },
  resources: AccessControlPageStream['resources']
): NavigationMenuGroup =>
  ({
    label: 'Login and SSO',
    iconName: IconName.SSO,
    items: [
      args.showSCIM && {
        label: 'SCIM token',
        id: AccessControlTabs.SCIM_TOKEN,
        content: () => <ManageSCIMToken />,
        description:
          'Generate a new SCIM token to manage users; it replaces the old one and must be kept secure.',
        KnowledgeBaseLink: KnowledgeBaseLink.SCIM_TOKEN_MANAGEMENT,
      },
      args.showSSOAttributeMapping && {
        label: 'SSO attribute mapping',
        id: AccessControlTabs.SSO_ATTRIBUTE_MAPPING,
        content: () => <SSOAttributeMapping />,
        description: SSO_MAPPING_STRINGS.HEADER.SUBTITLE,
        knowledgeBaseLink: KnowledgeBaseLink.SSO_MAPPING,
        rightText:
          resources.ssoAttributeMapping &&
          Object.keys(
            resources.ssoAttributeMapping.currentMapping
          ).length.toString(),
      },
    ].filter(ExcludeFalsy),
  }) satisfies NavigationMenuGroup;
